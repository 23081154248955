import icon from './images/bluedoor.png'; 
import landpageImage from './images/hero-image.png'; 
import './App.css';
import React from 'react'; 
import { Typography, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import blob from './images/blob.svg';
import promotion from './images/promotion.svg';
import whatsapp from './images/whatsapp.svg';
import web from './images/web.svg';
import phone from "./images/phone.svg"
import gift from './images/gift.svg';
import Footer from './components/footer.js';

import facebook from './images/facebook.svg'; 
import x from './images/x.svg'; 
// import youtube from './images/youtube.svg'; 
// import linkedin from './images/linkedin.svg'; 
import instagram from './images/instagram.svg'; 



function App() {
  return (
    <>
      <div className="header">
        <img src={icon}  alt="logo" style={{ height: '30px' }}/>
      </div>
      
      <div  style={{ marginTop: '10px', marginLeft:'20px', marginRight:'0px' }}>
        <div className="row landpage" >
          <div className="col-md-6">
            <div className='hero-content'> 
              <Typography variant="h1" className='hero-sub-text' gutterBottom>
                We're excited to introduce you to Bluedoor, an upcoming <br/> <span className='tech'>technology platform for the beauty and personal care industry</span> 
              </Typography>

              {/* <br /> */}

              {/* <Typography variant="h4" className='hero-text' gutterBottom>
                Our mission
              </Typography>

              <Typography variant="body1" className='hero-sub-text' gutterBottom>
                To create a tech revolution in the beauty industry, starting with automating appointment bookings to save time and enhance business efficiency
              </Typography>  */}

              <Button 
                variant="contained" 
                color="primary" 
                href="https://forms.gle/w6c8jgbsfJaepPHw6" 
                sx={{
                  height: '55px',
                  marginTop: { xs: '5px', md: '8px' }, 
                  alignSelf: { xs: 'flex-start', md: 'center' },
                }}
                className='mobile-width questionnaire-button landing-quesionnaire-button'
              >
                TAKE OUR MARKET SURVEY (3 MINS)
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <img src={landpageImage}  alt="landpageImage" className='image-fluid landpage-image '/>
          </div>
        </div>
        
        <div className="row incentives">
          <div className="col-md-3">
            <Typography variant="h4" className='hero-text-incentive' gutterBottom>
              Exciting rewards for our <span className="breaker">respondents </span>
            </Typography> 
            <p className='hero-sub-text-incentive'>
              Kindly note that incentives are available at product launch (T's & C's apply)
            </p>
          </div>
          <div className="col-md-3 feature-container">
            <div className='feature-logo'>
              <img src={web} alt="web" srcset="" />
            </div>
            Free promotion for your business <br /> on our website
          </div>
          <div className="col-md-3 feature-container">
            <div className='feature-logo'>
              <img src={promotion} alt="promotion" srcset=""/>
            </div>
            Free promotion for your business <br /> on our social media pages
          </div>
          <div className="col-md-3 feature-container">
            <div className='feature-logo'>
              <img src={gift} alt="gift" srcset="" />
            </div>
            Complimentary souvenirs upon <br /> our grand launch!
          </div>
        
        </div>
        

        <div className="row final-cta">
            <div className="col-md-12 final-cta-text text-center">
              <Typography variant="body" className='hero-sub-text' gutterBottom>
                <span className="Yourvoice">
                  Your voice matters 
                </span>
                
                <br/>
                
                Use it to shape the future of your business, your industry and enjoy exciting perks along the way.
              </Typography>
            </div> 
            <div className="col-md-12 text-center">
              <Button 
                variant="contained" 
                color="primary" 
                href="https://forms.gle/w6c8jgbsfJaepPHw6"
                sx={{
                  height: '55px',
                  marginTop: { xs: '5px', md: '8px' }, 
                  alignSelf: { xs: 'flex-start', md: 'center' },
                }}
                className='mobile-width questionnaire-button final-cta-button'
                
              >
                TAKE OUR MARKET SURVEY (3 MINS)
              </Button>
            </div> 
        </div>

        <div className="row contact">
          <div className="container">
            <div className="card text-center inquiry"> 
              <div className="card-body">
                <h2 className="card-title">For enquiries</h2>
                <p className="">
                  Contact us
                </p>
                <div className="row">
                  <div className="col-md-6 text-center contact-box">
                    <div className="text-center">
                      <strong> Olamide </strong> <br /> 
                    </div>
                    <div className="text-center mobile-contact">
                      <img src={phone} alt="phone" style={{height:'15px'}} className="lift" /> 
                      <a className='phoneNumber' href={`tel:+2348160245254`}>08160245254</a>
                    </div>
                    <div className="text-center mobile-contact">
                      <img src={whatsapp} alt="phone" style={{height:'20px'}} className="lift" /> 
                      <a className='phoneNumber' href="https://wa.link/6eonn9" target="_blank" rel="noreferrer">Whatsapp</a>
                    </div>
                  </div>
                  <div className="col-md-6 text-center contact-box2">
                    <div className="text-center" > 
                      <strong>Leke</strong> 
                    </div>
                    <div className="text-center mobile-contact">
                      <img src={phone} alt="phone" style={{height:'15px'}} className="lift"  />
                      <a className='phoneNumber' href={`tel:+2348038445144`}>08038445144</a> 
                    </div>
                    <div className="text-center mobile-contact">
                      <img src={whatsapp} alt="phone" style={{height:'20px'}} className="lift" />
                      <a className='phoneNumber' href="https://wa.link/dsc51g" target="_blank" rel="noreferrer">Whatsapp</a>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>

      </div> 

      <div className="text-center mt-5 mb-5 socials">
          <h2 className="text-center mb-3">Follow us on socials</h2>
          <a href="https://www.instagram.com/bluedoorapp?igsh=cjhreW50Yzg1eTZp&utm_source=qr" rel="noreferrer" target="_blank" 
          >
            <img src={instagram}  alt="facebook" style={{ height: '50px', marginRight:"20px" }}/>
          </a>
          <a href="https://x.com/bluedoorapp" target="_blank" rel="noreferrer">
            <img src={x}  alt="x" style={{ height: '50px', marginRight:"20px" }}/>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61559926140075" rel="noreferrer" target="_blank" >
            <img src={facebook}  alt="facebook" style={{ height: '50px', marginRight:"20px" }}/>
          </a>
          {/* <a href="#">
            <img src={linkedin}  alt="facebook" style={{ height: '50px', marginRight:"20px" }}/>
          </a>
          <a href="#">
            <img src={youtube}  alt="facebook" style={{ height: '50px', marginRight:"20px" }}/>
          </a> */}
        </div>

      
      <Divider className="divider" style={{ marginTop: '30px' }} />
      <Footer />

      <a href="https://wa.link/fbe1iz" target="_blank" rel="noreferrer" className="whatsapp">
        <img src={whatsapp}  alt="whatsapp" style={{ height: '50px' }}/>
      </a>

      <div class="blob">
        <img src={blob} alt="" />
      </div>
    </>
  );
}

export default App;

