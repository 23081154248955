import icon from '../images/bluedoor.png'; 
import '../App.css';
import React from 'react';

function Footer() {
    const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className='footer divider footer-space'>
        <img src={icon}  alt="logo" style={{ height: '30px' }}/>
        <p className='text-center footer-text mt-3 copyright'>
            &copy;  { currentYear } Bluedoor Team
        </p> 
      </footer> 
    </>
  );
}

export default Footer;

